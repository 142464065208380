// src/StepOne.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DOMParser } from 'xmldom';

const StepOne = ({ nextStep, handleChange, values }) => {
  const [error, setError] = useState('');
  const [countryCodes, setCountryCodes] = useState([]);

  useEffect(() => {
    axios.get('/phoneCodes.xml')
      .then(response => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response.data, 'text/xml');
        const items = xmlDoc.getElementsByTagName('item');
        const codes = Array.from(items).map(item => ({
          country: item.getElementsByTagName('country')[0].textContent,
          countryCode: item.getElementsByTagName('countryCode')[0].textContent
        }));
        setCountryCodes(codes);
        if (!values.countryCode) {
          handleChange('countryCode')({ target: { value: '+44' } });
        }
      })
      .catch(error => console.error('Error fetching country codes:', error));
  }, [handleChange, values.countryCode]);

  const handleNextClick = () => {
    if (!values.firstName || !values.lastName || !values.email || !values.password || !values.phone) {
      setError('Please fill out all fields.');
    } else {
      setError('');
      nextStep();
    }
  };

  return (
    <div className="bg-gray-700 p-4 rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Sign Up - Step 1</h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <input
          type="text"
          placeholder="First Name *"
          onChange={handleChange('firstName')}
          value={values.firstName}
          className="input input-bordered w-full"
          required
        />
        <input
          type="text"
          placeholder="Last Name *"
          onChange={handleChange('lastName')}
          value={values.lastName}
          className="input input-bordered w-full"
          required
        />
      </div>
      <input
        type="email"
        placeholder="Email Address *"
        onChange={handleChange('email')}
        value={values.email}
        className="input input-bordered w-full my-4"
        required
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <select
          className="input input-bordered w-full"
          onChange={handleChange('countryCode')}
          value={values.countryCode}
          required
        >
          {countryCodes.map(({ country, countryCode }, index) => (
            <option key={index} value={`+${countryCode}`}>+{countryCode} - {country}</option>
          ))}
        </select>
        <input
          type="tel"
          placeholder="Phone Number *"
          onChange={handleChange('phone')}
          value={values.phone}
          className="input input-bordered w-full"
          required
        />
      </div>
      <input
        type="password"
        placeholder="Password *"
        onChange={handleChange('password')}
        value={values.password}
        className="input input-bordered w-full my-4"
        required
      />
      <button onClick={handleNextClick} className="btn btn-primary w-full">Next</button>
    </div>
  );
};

export default StepOne;
