import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter
import AppNavigator from './AppNavigator';
import { AuthProvider } from './AuthContext';
import './tailwind.css';

function App() {
  return (
    <AuthProvider>
      <Router> {/* Wrap AppNavigator with Router */}
        <div className="App">
          <AppNavigator />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
