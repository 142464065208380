// src/Login.js

import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { Helmet } from 'react-helmet';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { app } from '../firebaseConfig';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const goBack = () => {
    navigate('/');
  };

  const handleLogin = async () => {
    setError('');
    try {
      const auth = getAuth(app);
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (error) {
      setError('Login failed, please try again!');
    }
  };

  const navigateToResetPassword = () => {
    navigate('/password-reset');
  };

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <Helmet>
        <title>Login - bloganywhere</title>
        {/* Add other SEO tags as needed */}
      </Helmet>
      <div className="container mx-auto p-4">
        <button onClick={goBack} className="btn btn-secondary mb-4">&#x2190; Back</button>
        <div className="bg-gray-700 p-4 rounded-lg shadow-md">
          <h1 className="text-3xl font-bold mb-4">Login to bloganywhere</h1>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input input-bordered w-full mb-4"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input input-bordered w-full mb-4"
            required
          />
          <button onClick={handleLogin} className="btn btn-primary w-full mb-4">Login</button>
          <button onClick={navigateToResetPassword} className="text-blue-500 underline w-full">Forgot password?</button>
        </div>
      </div>
    </div>
  );
};

export default Login;
