import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/outline';

const Dashboard = () => {
  const { currentUser } = useAuth();
  const [blogs, setBlogs] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [analyticsData, setAnalyticsData] = useState([]);
  const [updateDns, setUpdateDns] = useState(false);  // State for DNS banner

  useEffect(() => {
    const fetchBlogsAndAnalytics = async () => {
      if (currentUser) {
        try {
          const blogsRef = collection(db, 'users', currentUser.uid, 'blogs');
          const blogsSnapshot = await getDocs(blogsRef);
          const blogsList = blogsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

          setBlogs(blogsList);

          const allPosts = [];
          const analyticsDataArray = [];

          for (const blog of blogsList) {
            const postsRef = collection(db, 'users', currentUser.uid, 'blogs', blog.id, 'posts');
            const postsSnapshot = await getDocs(postsRef);
            const blogPosts = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), blogId: blog.id }));
            allPosts.push(...blogPosts);

            // Initialize views count for the blog
            let blogViews = 0;

            // Fetch blog home views
            const blogHomeAnalyticsDocRef = doc(db, 'users', currentUser.uid, 'blogs', blog.id, 'analytics', 'home');
            const blogHomeAnalyticsDoc = await getDoc(blogHomeAnalyticsDocRef);

            if (blogHomeAnalyticsDoc.exists()) {
              const data = blogHomeAnalyticsDoc.data();
              blogViews = data.views || 0;
            }

            // Initialize total post views for the blog
            let totalPostViews = 0;

            // Fetch post views
            for (const post of blogPosts) {
              const postAnalyticsDocRef = doc(db, 'users', currentUser.uid, 'blogs', blog.id, 'analytics', post.id);
              const postAnalyticsDoc = await getDoc(postAnalyticsDocRef);

              if (postAnalyticsDoc.exists()) {
                const data = postAnalyticsDoc.data();
                totalPostViews += data.postViews || 0;
              }
            }

            analyticsDataArray.push({
              blogId: blog.id,
              blogName: blog.websiteName || 'Unknown',
              views: blogViews,
              postViews: totalPostViews,
            });

            // Check DNS update status for the banner
            if (blog.requiresDnsUpdate) {
              setUpdateDns(true);  // Show the DNS banner if any blog requires DNS update
            }
          }

          setPosts(allPosts);
          setAnalyticsData(analyticsDataArray);

        } catch (error) {
          console.error(error);
          setError('Failed to fetch data. Please check your permissions.');
        }
        setLoading(false);
      }
    };

    fetchBlogsAndAnalytics();
  }, [currentUser]);

  const generateChartData = () => {
    if (!analyticsData || analyticsData.length === 0) return { labels: [], datasets: [] };

    const labels = analyticsData.map(data => stripLeadingHash(data.blogName));

    const viewsData = analyticsData.map(data => data.views);
    const postViewsData = analyticsData.map(data => data.postViews);

    return {
      labels: labels,
      datasets: [
        {
          label: 'Website Views',
          data: viewsData,
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
        },
        {
          label: 'Total Post Views',
          data: postViewsData,
          fill: false,
          backgroundColor: 'rgba(192,75,75,0.4)',
          borderColor: 'rgba(192,75,75,1)',
        },
      ],
    };
  };

  const stripLeadingHash = (title) => title.replace(/^#\s*/, '');

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <Navbar />

      {/* DNS Connector Banner */}
        <div className="bg-yellow-500 text-gray-800 py-2 text-center relative">
          <p className="text-lg font-semibold">Update your DNS settings to connect your site!</p>
          {blogs.length > 0 && (
            <Link to={`/dns-details/${currentUser.uid}-${blogs[0].id}`}>
              <button className="absolute right-4 top-1/2 transform -translate-y-1/2 text-black border border-black py-2 px-4 rounded-md hover:bg-yellow-700 hover:text-white transition duration-300 ease-in-out">
                View Instructions
              </button>
            </Link>
          )}
        </div>

      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Dashboard</h1>
        {currentUser ? (
          loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : blogs.length === 0 ? (
            <div>
              <p>You have no blogs yet.</p>
              <Link to="/add-blog" className="btn btn-primary mt-4">Add Blog</Link>
            </div>
          ) : (
            <div>
              <p className="mb-4">Logged in as: {currentUser.email}</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-gray-700 p-4 rounded-lg shadow-md">
                  <h2 className="text-2xl font-bold mb-4">Your Websites</h2>
                  <div className="space-y-4">
                    {blogs.map((blog, index) => (
                      <div key={index} className="bg-gray-800 p-4 rounded-lg shadow-md mb-4">
                        <h3 className="text-xl font-semibold flex justify-between">
                          {stripLeadingHash(blog.websiteName || 'Unknown')}
                          <Link to={`/edit-blog/${currentUser.uid}-${blog.id}`}>
                            <PencilIcon className="h-5 w-5 text-white" />
                          </Link>
                        </h3>
                        <p>{blog.description || 'No description available'}</p>
                        <a href={blog.websiteUrl} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">{blog.websiteUrl}</a>
                        <Link to={`/${currentUser.uid}-${blog.id}`} className="text-blue-500 underline block mt-2">View Blog</Link>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="bg-gray-700 p-4 rounded-lg shadow-md">
                  <h2 className="text-2xl font-bold mb-4">Website Traffic</h2>
                  <div className="relative h-64">
                    <Line data={generateChartData()} options={{ maintainAspectRatio: false, scales: { y: { beginAtZero: true } } }} />
                  </div>
                  <p className="text-sm text-gray-400 mt-2">* This is live data from your blogs.</p>
                </div>
              </div>

              <div className="bg-gray-700 p-4 rounded-lg shadow-md mt-8">
                <h2 className="text-2xl font-bold mb-4">Upcoming Posts</h2>
                <div className="overflow-x-auto">
                  <table className="table w-full">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Scheduled Date</th>
                        <th>Preview</th>
                      </tr>
                    </thead>
                    <tbody>
                      {posts.map((post, index) => (
                        <tr key={index}>
                          <td>{stripLeadingHash(post.title)}</td>
                          <td>{post.createdAt && post.createdAt.toDate().toDateString()}</td>
                          <td>
                            <Link to={`/${currentUser.uid}-${post.blogId}/post/${post.id}`} className="btn btn-sm btn-outline btn-info">View</Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        ) : (
          <p>Please log in to view your dashboard.</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
