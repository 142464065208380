import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Navbar = () => {
  const { logout } = useAuth();

  return (
    <nav className="navbar bg-gray-900 px-4 py-2">
      <div className="flex-1">
        <Link to="/dashboard" className="font-urbanist flex items-center justify-center gap-2 text-xl font-bold">
          <span>bloganywhe.re</span>
        </Link>
      </div>
      <div className="flex-none">
        <ul className="menu menu-horizontal items-center gap-4 px-1">
          <li><Link to="/dashboard">Dashboard</Link></li>
          <li><Link to="/posts">Posts</Link></li>
          <li><Link to="/profile">Profile</Link></li>
          <li>
            <Link to="/create-posts" className="btn btn-primary">Add Posts</Link>
          </li>
          <li><button onClick={logout} className="btn btn-secondary">Logout</button></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
