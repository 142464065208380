// firebaseConfig.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import getAuth
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD_Swdh35GKQvjj7E3rM-_30ahbdjapboU",
    authDomain: "bloganywhere-e842d.firebaseapp.com",
    projectId: "bloganywhere-e842d",
    storageBucket: "bloganywhere-e842d.appspot.com",
    messagingSenderId: "388050835935",
    appId: "1:388050835935:web:876d9b0338b3a78379cc6a",
    measurementId: "G-TNX418N6WY"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize Firebase Authentication
const db = getFirestore(app);

// Initialize analytics if needed, not exporting since it's not used elsewhere
getAnalytics(app);

// Export the app and auth
export { app, auth, db };