// src/Posts.js

import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import axios from 'axios';
import { collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

const Posts = () => {
  const { currentUser } = useAuth();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [generating, setGenerating] = useState(false);

  useEffect(() => {
    const fetchPosts = async () => {
      if (currentUser) {
        try {
          const blogsRef = collection(db, 'users', currentUser.uid, 'blogs');
          const blogsSnapshot = await getDocs(blogsRef);
          const blogsList = blogsSnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
          
          const allPosts = [];
          for (const blog of blogsList) {
            const postsRef = collection(db, 'users', currentUser.uid, 'blogs', blog.id, 'posts');
            const postsSnapshot = await getDocs(postsRef);
            const blogPosts = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), blogId: blog.id }));
            allPosts.push(...blogPosts);
          }

          setPosts(allPosts);
        } catch (error) {
          setError('Failed to fetch posts. Please check your permissions.');
        }
        setLoading(false);
      }
    };

    fetchPosts();
  }, [currentUser]);

  const handleGeneratePosts = async () => {
    setGenerating(true);
    setError('');
    try {
      const blogsRef = collection(db, 'users', currentUser.uid, 'blogs');
      const blogsSnapshot = await getDocs(blogsRef);
      const blogsList = blogsSnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));

      if (blogsList.length > 0) {
        const blog = blogsList[0]; // Assuming you want to generate posts for the first blog

        const existingTitles = [];
        const postsRef = collection(db, 'users', currentUser.uid, 'blogs', blog.id, 'posts');
        const postsSnapshot = await getDocs(postsRef);
        postsSnapshot.docs.forEach(doc => {
          existingTitles.push(doc.data().title);
        });

        const response = await axios.post('https://post-generator-4nbyelqcha-ew.a.run.app/generate-posts', {
          url: blog.data.websiteUrl,
          uid: currentUser.uid,
          num_posts: 2,
          existing_titles: existingTitles,
        });

        const newPosts = response.data;

        for (const postContent of newPosts) {
          await addDoc(collection(db, 'users', currentUser.uid, 'blogs', blog.id, 'posts'), {
            title: postContent.split('\n')[0].replace('Title: ', ''),
            content: postContent.split('\n').slice(1).join('\n'),
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          });
        }

        // Refresh the posts list
        const refreshedPostsSnapshot = await getDocs(postsRef);
        const refreshedBlogPosts = refreshedPostsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), blogId: blog.id }));
        setPosts(refreshedBlogPosts);

      } else {
        setError('No blogs found to generate posts.');
      }

    } catch (error) {
      console.error('Error generating posts:', error); // Log the detailed error
      setError('Failed to generate posts. Please try again.');
    }
    setGenerating(false);
  };

  const stripLeadingHash = (title) => title.replace(/^#\s*/, '');

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <Navbar />
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">All Posts</h1>
        {currentUser ? (
          loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : (
            <div>
              <button 
                onClick={handleGeneratePosts} 
                className="btn btn-primary mb-4"
                disabled={generating}
              >
                {generating ? 'Generating...' : 'Generate 2 More Posts'}
              </button>
              <div className="bg-gray-700 p-4 rounded-lg shadow-md">
                <div className="overflow-x-auto">
                  <table className="table w-full">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Date</th>
                        <th>Preview</th>
                      </tr>
                    </thead>
                    <tbody>
                      {posts.map((post, index) => (
                        <tr key={index}>
                          <td>{stripLeadingHash(post.title)}</td>
                          <td>{post.createdAt && post.createdAt.toDate().toDateString()}</td>
                          <td>
                            <Link to={`/blog/${currentUser.uid}-${post.blogId}/post/${post.id}`} className="btn btn-sm btn-outline btn-info">
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        ) : (
          <p>Please log in to view the posts.</p>
        )}
      </div>
    </div>
  );
};

export default Posts;