// src/Profile.js

import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { updateEmail, updatePassword } from 'firebase/auth';
import Navbar from './Navbar';

const Profile = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userDoc = doc(db, 'users', currentUser.uid);
          const userSnapshot = await getDoc(userDoc);
          if (userSnapshot.exists()) {
            setUserData(userSnapshot.data());
          } else {
            setError('User data not found');
          }
        } catch (error) {
          setError('Failed to fetch user data');
        }
        setLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      if (userData.email && userData.email !== currentUser.email) {
        await updateEmail(currentUser, userData.email);
      }
      if (password) {
        await updatePassword(currentUser, password);
      }
      const userDoc = doc(db, 'users', currentUser.uid);
      await updateDoc(userDoc, userData);
      setSuccess('Profile updated successfully');
    } catch (error) {
      setError('Failed to update profile');
    }
  };

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <Navbar />
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Profile</h1>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <form onSubmit={handleSubmit} className="bg-gray-700 p-4 rounded-lg shadow-md">
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="firstName">First Name</label>
              <input
                type="text"
                name="firstName"
                value={userData.firstName || ''}
                onChange={handleChange}
                className="input input-bordered w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="lastName">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={userData.lastName || ''}
                onChange={handleChange}
                className="input input-bordered w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="location">Location</label>
              <input
                type="text"
                name="location"
                value={userData.location || ''}
                onChange={handleChange}
                className="input input-bordered w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="phone">Phone</label>
              <input
                type="text"
                name="phone"
                value={userData.phone || ''}
                onChange={handleChange}
                className="input input-bordered w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                value={userData.email || currentUser.email}
                onChange={handleChange}
                className="input input-bordered w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                className="input input-bordered w-full"
                placeholder="Leave blank to keep current password"
              />
            </div>
            <div className="flex items-center justify-between">
              <button type="submit" className="btn btn-primary">Update Profile</button>
            </div>
            {success && <p className="text-green-500 mt-4">{success}</p>}
          </form>
        )}
      </div>
    </div>
  );
};

export default Profile;