// src/AddBlog.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { db } from './firebaseConfig';
import { collection, addDoc, getDocs, setDoc, serverTimestamp } from 'firebase/firestore';
import Navbar from './Navbar';
import { useAuth } from './AuthContext';

const AddBlog = () => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    let timer;
    if (loading) {
      const messages = [
        'Praying to Sam Altman...',
        'Hiking through the web...',
        'Counting virtual sheep...',
        'Whispering to the cloud...',
        'Consulting the AI oracle...',
      ];
      let index = 0;

      timer = setInterval(() => {
        setLoadingMessage(messages[index]);
        index = (index + 1) % messages.length;
      }, 2000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [loading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    console.log('Submitting blog URL:', url);

    try {
      // Step 1: Fetch and store the blog information
      console.log('Fetching website information...');
      const response = await axios.post('https://post-generator-4nbyelqcha-ew.a.run.app/fetch-website', {
        url: url,
        uid: currentUser.uid,
      });

      console.log('Website information fetched:', response.data);

      const themeData = response.data;

      // Flatten the arrays if necessary
      const flattenArray = (arr) => [].concat(...arr);

      // Check if the blog already exists
      console.log('Checking if the blog already exists...');
      const blogsRef = collection(db, 'users', currentUser.uid, 'blogs');
      const blogsSnapshot = await getDocs(blogsRef);
      let blogDocRef = null;

      blogsSnapshot.forEach((doc) => {
        if (doc.data().websiteUrl === url) {
          blogDocRef = doc.ref;
        }
      });

      if (!blogDocRef) {
        console.log('Blog does not exist, creating new blog...');
        blogDocRef = await addDoc(collection(db, 'users', currentUser.uid, 'blogs'), {
          websiteUrl: url,
          websiteName: new URL(url).hostname,
          description: themeData.description,
          headings: flattenArray(themeData.headings),
          keywords: flattenArray(themeData.keywords),
          keywords_meta: themeData.keywords_meta,
          topics: flattenArray(themeData.topics),
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        console.log('New blog created:', blogDocRef.id);
      } else {
        // Update the existing blog with new theme data
        console.log('Blog already exists, updating blog...');
        await setDoc(blogDocRef, {
          description: themeData.description,
          headings: flattenArray(themeData.headings),
          keywords: flattenArray(themeData.keywords),
          keywords_meta: themeData.keywords_meta,
          topics: flattenArray(themeData.topics),
          updatedAt: serverTimestamp(),
        }, { merge: true });
        console.log('Blog updated:', blogDocRef.id);
      }

      // Step 2: Generate posts for the new blog
      const existingTitles = [];

      console.log('Generating posts...');
      const postsResponse = await axios.post('https://post-generator-4nbyelqcha-ew.a.run.app/generate-posts', {
        url: url,
        uid: currentUser.uid,
        num_posts: 1,
        existing_titles: existingTitles,
      });

      const posts = postsResponse.data;
      console.log('Posts generated:', posts);

      for (const postContent of posts) {
        await addDoc(collection(db, 'users', currentUser.uid, 'blogs', blogDocRef.id, 'posts'), {
          title: postContent.split('\n')[0].replace('Title: ', ''),
          content: postContent.split('\n').slice(1).join('\n'),
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        console.log('Post added to Firestore:', postContent.split('\n')[0].replace('Title: ', ''));
      }

      setLoading(false);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error generating posts:', error); // Log the detailed error
      setLoading(false);
      setError('Failed to generate posts. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <Navbar />
      <div className="container mx-auto p-4">
        {loading ? (
          <div className="flex flex-col justify-center items-center h-full">
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-4">
              <div className="bg-blue-600 h-2.5 rounded-full animate-pulse"></div>
            </div>
            <p className="text-xl font-semibold">{loadingMessage}</p>
          </div>
        ) : (
          <div>
            <h1 className="text-3xl font-bold mb-4">Add New Blog</h1>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="url" className="block text-sm font-medium text-gray-200">
                  Blog URL
                </label>
                <input
                  type="url"
                  id="url"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  required
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-700 text-white"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Add Blog
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddBlog;