// src/Welcome.js

import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { Helmet } from 'react-helmet';

const Welcome = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/');
  };

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <Helmet>
        <title>Welcome to bloganywhere</title>
        {/* Add other SEO tags as needed */}
      </Helmet>
      <div className="container mx-auto p-4">
        <button onClick={goBack} className="btn btn-secondary mb-4">&#x2190; Back</button>
        <div className="bg-gray-700 p-4 rounded-lg shadow-md text-center">
          <h1 className="text-3xl font-bold mb-4">Welcome to bloganywhere!</h1>
          <p className="mb-4">Signup or login below</p>
          <button className="btn btn-primary mb-4 w-full" onClick={() => navigate('/login')}>Login</button>
          <button className="btn btn-secondary w-full" onClick={() => navigate('/signup')}>Signup</button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;