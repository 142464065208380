// src/Signup.js

import React, { useState, useContext, useEffect } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { createUser } from './SignupProcessor';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { Helmet } from 'react-helmet';

const Signup = () => {
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    location: '',
    countryCode: '+44',
    marketingConsent: false,
    terms: false
  });

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = input => e => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const goBack = () => {
    navigate('/');
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!Object.values(formData).every(value => typeof value === 'string' ? value.trim() !== '' : true)) {
        throw new Error('Please fill out all fields.');
      }
      const fullPhoneNumber = `${formData.countryCode}${formData.phone}`;
      await createUser(formData.email, formData.password, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: fullPhoneNumber,
        email: formData.email,
        location: formData.location,
        marketingConsent: formData.marketingConsent,
        terms: formData.terms
      });
      navigate('/dashboard');
    } catch (error) {
      console.error('Signup error:', error);
      alert(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitting) {
    return (
      <div className="min-h-screen bg-gray-800 flex items-center justify-center text-white">
        <div className="text-center">
          <h1 className="text-3xl font-bold">Creating your account...</h1>
        </div>
      </div>
    );
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return <StepOne nextStep={nextStep} handleChange={handleChange} values={formData} />;
      case 2:
        return <StepTwo prevStep={prevStep} handleChange={handleChange} handleSubmit={handleSubmit} values={formData} />;
      default:
        return <div>Step {step}</div>;
    }
  };

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <Helmet>
        <title>Sign Up - bloganywhere</title>
        {/* Add other SEO tags as needed */}
      </Helmet>
      <div className="container mx-auto p-4">
        <button onClick={goBack} className="btn btn-secondary mb-4">&#x2190; Back</button>
        {renderStep()}
      </div>
    </div>
  );
};

export default Signup;
