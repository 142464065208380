import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { useParams } from 'react-router-dom';

const DnsDetails = () => {
  const { currentUser } = useAuth();
  const [dnsInstructions, setDnsInstructions] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { blogId } = useParams();

  useEffect(() => {
    const fetchBlogDetails = async () => {
      if (currentUser) {
        try {
          const [userId, blogDocId] = blogId.split('-');
          console.log('User ID:', userId, 'Blog ID:', blogDocId);

          if (userId !== currentUser.uid) {
            setError('Invalid blog ID');
            setLoading(false);
            return;
          }

          const blogRef = doc(db, 'users', userId, 'blogs', blogDocId);
          const blogDoc = await getDoc(blogRef);

          if (blogDoc.exists()) {
            console.log('Blog found:', blogDoc.data());
            const blogData = blogDoc.data();
            const domain = blogData.websiteUrl.replace(/^https?:\/\//, '').replace(/\/$/, ''); // Extract domain from URL
            const txtRecordValue = `blog_identifier=${blogId}`;

            const instructions = `To connect your site, please set up the following DNS records:

Type: CNAME
Name: blog
Content: ${domain}

Type: TXT
Name: _blogid.blog.${domain}
Content: "${txtRecordValue}"

TTL: 60`;

            setDnsInstructions(instructions);
          } else {
            console.log('Blog not found for ID:', blogId);
            setError('Blog not found');
          }
        } catch (error) {
          console.error('Error fetching blog details:', error);
          setError('Failed to fetch blog details. Please check your permissions.');
        }
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, [currentUser, blogId]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(dnsInstructions)
      .then(() => alert('DNS instructions copied to clipboard!'))
      .catch(err => console.error('Failed to copy text: ', err));
  };

  return (
    <div className="min-h-screen bg-gray-800 text-white p-4">
      <h1 className="text-3xl font-bold mb-4">DNS Setup Instructions</h1>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="bg-gray-700 p-4 rounded-lg shadow-md">
          <p className="mb-4">Please add the following DNS records to connect your site:</p>
          <textarea
            readOnly
            value={dnsInstructions}
            className="w-full h-40 bg-gray-800 text-white p-2 rounded-md border border-gray-600 resize-none"
          />
          <button
            onClick={copyToClipboard}
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
          >
            Copy to Clipboard
          </button>
        </div>
      )}
    </div>
  );
};

export default DnsDetails;