// src/components/AnalyticsCounter.js

import React, { useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, setDoc, increment } from 'firebase/firestore';

const AnalyticsCounter = ({ path, isPost }) => {
  useEffect(() => {
    const incrementViewCount = async () => {
      try {
        // Adjust path structure to ensure even number of segments
        const docRef = isPost 
          ? doc(db, `${path}/analytics`, isPost) // Adds a document reference for each post within 'analytics' collection
          : doc(db, `${path}/analytics`, 'home'); // Uses 'home' as a document reference for the home view analytics
          
        const field = isPost ? { postViews: increment(1) } : { views: increment(1) };
        await setDoc(docRef, field, { merge: true });
      } catch (error) {
        console.error("Error incrementing view count: ", error);
      }
    };

    incrementViewCount();
  }, [path, isPost]);

  return null; // This component doesn't render anything in the UI
};

export default AnalyticsCounter;