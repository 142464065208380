import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { ClipLoader } from 'react-spinners';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import AnalyticsCounter from '../components/AnalyticsCounter';

const UserBlogHome = () => {
  const { blogId } = useParams();
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [websiteName, setWebsiteName] = useState('');
  const [themeColor, setThemeColor] = useState('#000000');
  const [textColor, setTextColor] = useState('#FFFFFF');
  const [originalUrl, setOriginalUrl] = useState('');
  const [loading, setLoading] = useState(true);

  const [userId, actualBlogId] = blogId ? blogId.split('-') : [];

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        if (!blogId) {
          throw new Error("Blog ID is undefined");
        }

        const postsCollectionPath = `users/${userId}/blogs/${actualBlogId}/posts`;
        const blogDocPath = `users/${userId}/blogs/${actualBlogId}`;

        const blogDoc = await getDoc(doc(db, blogDocPath));
        if (blogDoc.exists()) {
          const blogData = blogDoc.data();
          setWebsiteName(blogData.websiteName || 'Blog');
          const color = blogData.colors && blogData.colors.length > 0 ? blogData.colors[0] : '#000000';
          setThemeColor(color);
          setTextColor(getTextColor(color));
          setOriginalUrl(blogData.websiteUrl || '');
        } else {
          setWebsiteName('Blog');
        }

        const postsCollection = collection(db, postsCollectionPath);
        const postsSnapshot = await getDocs(postsCollection);

        if (postsSnapshot.empty) {
          setError("No posts found for this blog.");
          return;
        }

        const postsData = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPosts(postsData);
      } catch (err) {
        console.error('Error fetching posts:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [blogId, userId, actualBlogId]);

  const stripLeadingHash = (title) => title.replace(/^#\s*/, '');

  const getTextColor = (bgColor) => {
    if (!bgColor) return '#000000';
    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 186 ? '#000000' : '#FFFFFF';
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <ClipLoader color="#4A90E2" loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return <div className="max-w-4xl mx-auto px-4 py-8 text-red-500">{error}</div>;
  }

  return (
    <div className="min-h-screen bg-white text-black">
      {/* Analytics counter for homepage */}
      <AnalyticsCounter path={`users/${userId}/blogs/${actualBlogId}`} />

      <div className="w-full h-32 flex items-center justify-between px-4" style={{ backgroundColor: themeColor, color: textColor }}>
        {originalUrl && (
          <a
            href={originalUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white text-black py-2 px-4 rounded hover:bg-gray-200 transition duration-300 whitespace-nowrap"
          >
            Back to Site
          </a>
        )}
        <h1 className="text-4xl font-bold text-center w-full">{websiteName}</h1>
      </div>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <ul className="space-y-6">
          {posts.map(post => (
            <li key={post.id} className="bg-white text-black p-6 shadow rounded-lg hover:shadow-xl transition-shadow duration-200">
              <a href={`/${blogId}/post/${post.id}`} className="text-2xl font-semibold text-black hover:underline">
                {stripLeadingHash(post.title)}
              </a>
              <div className="mt-2 text-gray-700">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {post.content ? post.content.substring(0, 100) + '...' : ''}
                </ReactMarkdown>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UserBlogHome;