import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { db } from './firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import Navbar from './Navbar';

const EditBlog = () => {
  const { currentUser } = useAuth();
  const { blogId } = useParams();
  const [blogData, setBlogData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchBlogData = async () => {
      if (currentUser && blogId) {
        try {
          console.log(`Full blogId: ${blogId}`);
          const [userId, actualBlogId] = blogId.split('-');
          console.log(`Extracted userId: ${userId}, actualBlogId: ${actualBlogId}`);
          const blogRef = doc(db, 'users', userId, 'blogs', actualBlogId);
          const blogSnap = await getDoc(blogRef);

          if (blogSnap.exists()) {
            console.log(`Blog data: ${JSON.stringify(blogSnap.data())}`);
            setBlogData(blogSnap.data());
          } else {
            console.error('No such blog found.');
            setError('No such blog found.');
          }
        } catch (error) {
          console.error('Failed to fetch blog data.', error);
          setError('Failed to fetch blog data.');
        }
        setLoading(false);
      } else {
        console.error('User or blogId is missing.');
      }
    };

    fetchBlogData();
  }, [currentUser, blogId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBlogData({ ...blogData, [name]: value });
  };

  const handleArrayChange = (e, arrayName, index) => {
    const newArray = [...blogData[arrayName]];
    newArray[index] = e.target.value;
    setBlogData({ ...blogData, [arrayName]: newArray });
  };

  const handleArrayAdd = (arrayName) => {
    const newArray = [...blogData[arrayName], ''];
    setBlogData({ ...blogData, [arrayName]: newArray });
  };

  const handleArrayRemove = (arrayName, index) => {
    const newArray = blogData[arrayName].filter((_, i) => i !== index);
    setBlogData({ ...blogData, [arrayName]: newArray });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const [userId, actualBlogId] = blogId.split('-');
      const blogRef = doc(db, 'users', userId, 'blogs', actualBlogId);
      await setDoc(blogRef, blogData, { merge: true });
      alert('Blog updated successfully');
    } catch (error) {
      console.error('Failed to update blog.', error);
      setError('Failed to update blog.');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <Navbar />
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Edit Blog</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="websiteName">
              Website Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:shadow-outline"
              id="websiteName"
              name="websiteName"
              type="text"
              value={blogData.websiteName || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="description">
              Description
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:shadow-outline"
              id="description"
              name="description"
              value={blogData.description || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="websiteUrl">
              Website URL
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:shadow-outline"
              id="websiteUrl"
              name="websiteUrl"
              type="text"
              value={blogData.websiteUrl || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Colors
            </label>
            {blogData.colors && blogData.colors.map((color, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={color}
                  onChange={(e) => handleArrayChange(e, 'colors', index)}
                />
                <input
                  type="color"
                  value={color}
                  onChange={(e) => handleArrayChange(e, 'colors', index)}
                  className="ml-2"
                />
                <button
                  type="button"
                  className="ml-2 bg-red-500 text-white px-2 py-1 rounded"
                  onClick={() => handleArrayRemove('colors', index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              className="bg-blue-500 text-white px-2 py-1 rounded"
              onClick={() => handleArrayAdd('colors')}
            >
              Add Color
            </button>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Keywords
            </label>
            {blogData.keywords && blogData.keywords.map((keyword, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={keyword}
                  onChange={(e) => handleArrayChange(e, 'keywords', index)}
                />
                <button
                  type="button"
                  className="ml-2 bg-red-500 text-white px-2 py-1 rounded"
                  onClick={() => handleArrayRemove('keywords', index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              className="bg-blue-500 text-white px-2 py-1 rounded"
              onClick={() => handleArrayAdd('keywords')}
            >
              Add Keyword
            </button>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Headings
            </label>
            {blogData.headings && blogData.headings.map((heading, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={heading}
                  onChange={(e) => handleArrayChange(e, 'headings', index)}
                />
                <button
                  type="button"
                  className="ml-2 bg-red-500 text-white px-2 py-1 rounded"
                  onClick={() => handleArrayRemove('headings', index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              className="bg-blue-500 text-white px-2 py-1 rounded"
              onClick={() => handleArrayAdd('headings')}
            >
              Add Heading
            </button>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="keywords_meta">
              Keywords Meta
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:shadow-outline"
              id="keywords_meta"
              name="keywords_meta"
              type="text"
              value={blogData.keywords_meta || ''}
              onChange={handleInputChange}
            />
          </div>
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300"
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditBlog;