import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Welcome from './signup/Welcome';
import Signup from './signup/Signup';
import Login from './signup/Login';
import PasswordReset from './signup/PasswordReset';
import Dashboard from './Dashboard';
import Posts from './Posts';
import AddBlog from './AddBlog';
import Profile from './Profile';
import UserBlogHome from './UserBlogs/UserBlogHome';
import UserBlogPost from './UserBlogs/UserBlogPost';
import DnsDetails from './DnsDetails';
import CreatePosts from './CreatePosts';
import FullPost from './FullPost';
import EditBlog from './EditBlog';
import './styles/style.css';

const AppNavigator = ({ blogIdentifier }) => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/add-blog" element={<AddBlog />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/dns-details/:blogId" element={<DnsDetails />} />
        <Route path="/create-posts" element={<CreatePosts />} />
        <Route path="/blog/:blogId/post/:postId" element={<FullPost />} />
        <Route path="/:blogId/post/:postId" element={<UserBlogPost />} />
        <Route path="/:blogId" element={<UserBlogHome blogIdentifier={blogIdentifier} />} />
        <Route path="/edit-blog/:blogId" element={<EditBlog />} />
      </Routes>
    </div>
  );
};

export default AppNavigator;
