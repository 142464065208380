import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { ClipLoader } from 'react-spinners';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { FaArrowLeft } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import AnalyticsCounter from '../components/AnalyticsCounter'; // Import the AnalyticsCounter component

const UserBlogPost = () => {
  const { blogId, postId } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [themeColor, setThemeColor] = useState('#000000'); // Default header color
  const [textColor, setTextColor] = useState('#FFFFFF'); // Default text color
  const [originalUrl, setOriginalUrl] = useState('');
  const [blogData, setBlogData] = useState({});
  const navigate = useNavigate();

  const [userId, actualBlogId] = blogId ? blogId.split('-') : [];

  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (!blogId || !postId) {
          throw new Error("Blog ID or Post ID is undefined");
        }

        const postDocPath = `users/${userId}/blogs/${actualBlogId}/posts/${postId}`;
        const blogDocPath = `users/${userId}/blogs/${actualBlogId}`;

        const postDocRef = doc(db, postDocPath);
        const postDoc = await getDoc(postDocRef);

        const blogDocRef = doc(db, blogDocPath);
        const blogDoc = await getDoc(blogDocRef);

        if (blogDoc.exists()) {
          const blogData = blogDoc.data();
          const color = blogData.colors ? blogData.colors[0] : '#000000';
          setThemeColor(color || '#000000');
          setTextColor(getTextColor(color) || '#FFFFFF');
          setOriginalUrl(blogData.websiteUrl || '');
          setBlogData(blogData);
        }

        if (!postDoc.exists()) {
          setError("No such post found.");
          return;
        }

        setPost(postDoc.data());
      } catch (err) {
        console.error('Error fetching post:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [blogId, postId, userId, actualBlogId]);

  const stripLeadingHash = (title) => title.replace(/^#\s*/, '');

  const getTextColor = (bgColor) => {
    if (!bgColor || typeof bgColor !== 'string') {
      return '#FFFFFF'; // default to white text color if bgColor is undefined or not a string
    }
    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const brightness= (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 186 ? '#000000' : '#FFFFFF';
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <ClipLoader color="#4A90E2" loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return <div className="max-w-4xl mx-auto px-4 py-8 text-red-500">{error}</div>;
  }

  if (!post) {
    return <div className="max-w-4xl mx-auto px-4 py-8">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-white text-black">
      {/* SEO Metadata */}
      <Helmet>
        <title>{blogData.websiteName || "Blog Post"} - {stripLeadingHash(post.title)}</title>
        <meta name="description" content={blogData.description || post.content?.substring(0, 150) || "Read more about this post."} />
        <meta name="keywords" content={(blogData.keywords || []).join(', ')} />
      </Helmet>

      {/* Analytics counter for the post */}
      <AnalyticsCounter path={`users/${userId}/blogs/${actualBlogId}`} isPost={postId} />

      <div className="w-full h-32 flex items-center justify-center text-center px-4 relative" style={{ backgroundColor: themeColor || '#000000', color: textColor || '#FFFFFF' }}>
        <button
          className="absolute left-4 px-4 py-2 bg-white text-black rounded hover:bg-gray-200"
          onClick={() => navigate(`/${blogId}`)}
        >
          <FaArrowLeft />
        </button>
        <h1 className="text-4xl font-bold">{stripLeadingHash(post.title)}</h1>
        {originalUrl && (
          <a
            href={originalUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="absolute right-4 bg-white text-black py-2 px-4 rounded hover:bg-gray-200 transition duration-300 whitespace-nowrap"
          >
            Back to Site
          </a>
        )}
      </div>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="prose prose-lg text-black">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              h1: ({ node, ...props }) => <h1 className="text-3xl font-bold mb-4" {...props} />,
              h2: ({ node, ...props }) => <h2 className="text-2xl font-bold mb-4" {...props} />,
              h3: ({ node, ...props }) => <h3 className="text-xl font-semibold mb-4" {...props} />,
              p: ({ node, ...props }) => <p className="mb-4 leading-relaxed" {...props} />,
              strong: ({ node, ...props }) => <strong className="font-bold" {...props} />,
              em: ({ node, ...props }) => <em className="italic" {...props} />,
              ul: ({ node, ...props }) => <ul className="list-disc list-inside mb-4" {...props} />,
              ol: ({ node, ...props }) => <ol className="list-decimal list-inside mb-4" {...props} />,
              li: ({ node, ...props }) => <li className="mb-2" {...props} />,
              blockquote: ({ node, ...props }) => <blockquote className="border-l-4 border-gray-500 pl-4 italic text-gray-600 mb-4" {...props} />,
              code: ({ node, ...props }) => <code className="bg-gray-200 rounded p-1 text-red-600" {...props} />,
            }}
          >
            {post.content || ''}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default UserBlogPost;