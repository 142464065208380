import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Navbar from './Navbar';
import { ClipLoader } from 'react-spinners';
import { FaArrowLeft } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const FullPost = () => {
  const { currentUser } = useAuth();
  const { blogId, postId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (!blogId || !postId) {
          throw new Error('Blog ID or Post ID is undefined');
        }

        const [userId, actualBlogId] = blogId.split('-');
        const postDocPath = `users/${userId}/blogs/${actualBlogId}/posts/${postId}`;
        console.log(`Fetching post from document path: ${postDocPath}`);

        const postDocRef = doc(db, postDocPath);
        const postDoc = await getDoc(postDocRef);

        console.log('Post document:', postDoc);

        if (!postDoc.exists()) {
          setError('No such post found.');
          return;
        }

        console.log('Post data:', postDoc.data());
        setPost(postDoc.data());
      } catch (err) {
        console.error('Error fetching post:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [blogId, postId]);

  const stripLeadingHash = (title) => title.replace(/^#\s*/, '');

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <Navbar />
      <div className="container mx-auto p-4">
        {loading ? (
          <div className="flex justify-center items-center h-screen bg-white">
            <ClipLoader color="#4A90E2" loading={loading} size={50} />
          </div>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : post ? (
          <div>
            <button
              className="absolute left-4 px-4 py-2 bg-white text-black rounded hover:bg-gray-200"
              onClick={() => navigate('/dashboard')} // Navigate back to the dashboard
            >
              <FaArrowLeft />
            </button>
            <h1 className="text-3xl font-bold mb-4">{stripLeadingHash(post.title)}</h1>
            <p className="mb-4">{post.createdAt && post.createdAt.toDate().toDateString()}</p>
            <div className="bg-gray-700 p-4 rounded-lg shadow-md">
              <ReactMarkdown
                children={post.content}
                remarkPlugins={[remarkGfm]}
                components={{
                  h1: ({ node, ...props }) => <h1 className="text-2xl font-bold mb-2" {...props} />,
                  h2: ({ node, ...props }) => <h2 className="text-xl font-bold mb-2" {...props} />,
                  h3: ({ node, ...props }) => <h3 className="text-lg font-bold mb-2" {...props} />,
                  p: ({ node, ...props }) => <p className="mb-2 text-gray-300" {...props} />,
                  ul: ({ node, ...props }) => <ul className="list-disc list-inside mb-2" {...props} />,
                  ol: ({ node, ...props }) => <ol className="list-decimal list-inside mb-2" {...props} />,
                  li: ({ node, ...props }) => <li className="mb-1 text-gray-300" {...props} />,
                  strong: ({ node, ...props }) => <strong className="font-semibold" {...props} />,
                }}
              />
            </div>
          </div>
        ) : (
          <p>Post not found.</p>
        )}
      </div>
    </div>
  );
};

export default FullPost;