// src/PasswordReset.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { app } from '../firebaseConfig';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    setMessage('');
    try {
      const auth = getAuth(app);
      await sendPasswordResetEmail(auth, email);
      setMessage('Check your email for the password reset link.');
      setEmailSent(true);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <div className="container mx-auto p-4">
        <button onClick={() => navigate('/login')} className="btn btn-secondary mb-4">&#x2190; Back</button>
        <div className="bg-gray-700 p-4 rounded-lg shadow-md">
          <h1 className="text-3xl font-bold mb-4">Reset Password</h1>
          {message && <div className={emailSent ? "text-green-500 mb-4" : "text-red-500 mb-4"}>{message}</div>}
          {!emailSent && (
            <>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input input-bordered w-full mb-4"
                required
              />
              <button onClick={handlePasswordReset} className="btn btn-primary w-full">Send Reset Email</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
