import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import axios from 'axios';
import { collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Navbar from './Navbar';

const CreatePosts = () => {
  const { currentUser } = useAuth();
  const [mode, setMode] = useState('ai'); // 'ai' or 'manual'
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [numPosts, setNumPosts] = useState(2);
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState('');

  const handleGeneratePosts = async () => {
    setGenerating(true);
    setError('');
    try {
      const blogsRef = collection(db, 'users', currentUser.uid, 'blogs');
      const blogsSnapshot = await getDocs(blogsRef);
      const blogsList = blogsSnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));

      if (blogsList.length > 0) {
        const blog = blogsList[0];

        const existingTitles = [];
        const postsRef = collection(db, 'users', currentUser.uid, 'blogs', blog.id, 'posts');
        const postsSnapshot = await getDocs(postsRef);
        postsSnapshot.docs.forEach(doc => {
          existingTitles.push(doc.data().title);
        });

        const response = await axios.post('https://post-generator-4nbyelqcha-ew.a.run.app/generate-posts', {
          url: blog.data.websiteUrl,
          uid: currentUser.uid,
          num_posts: numPosts,
          existing_titles: existingTitles,
        });

        const newPosts = response.data;

        for (const postContent of newPosts) {
          await addDoc(postsRef, {
            title: postContent.split('\n')[0].replace('Title: ', ''),
            content: postContent.split('\n').slice(1).join('\n'),
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          });
        }

        alert('Posts generated successfully');
      } else {
        setError('No blogs found to generate posts.');
      }
    } catch (error) {
      console.error('Error generating posts:', error);
      setError('Failed to generate posts. Please try again.');
    }
    setGenerating(false);
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    try {
      const blogsRef = collection(db, 'users', currentUser.uid, 'blogs');
      const blogsSnapshot = await getDocs(blogsRef);
      const blogsList = blogsSnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));

      if (blogsList.length > 0) {
        const blog = blogsList[0];

        await addDoc(collection(db, 'users', currentUser.uid, 'blogs', blog.id, 'posts'), {
          title,
          content,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        alert('Post created successfully');
        setTitle('');
        setContent('');
      } else {
        setError('No blogs found to create a post.');
      }
    } catch (error) {
      console.error('Error creating post:', error);
      setError('Failed to create post. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <Navbar />
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Create Posts</h1>
        <div className="mb-4">
          <button
            className={`btn mr-2 ${mode === 'ai' ? 'btn-primary' : 'btn-secondary'}`}
            onClick={() => setMode('ai')}
          >
            Generate AI Posts
          </button>
          <button
            className={`btn ${mode === 'manual' ? 'btn-primary' : 'btn-secondary'}`}
            onClick={() => setMode('manual')}
          >
            Write Manual Post
          </button>
        </div>
        {mode === 'ai' ? (
          <div>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="numPosts">
                Number of Posts to Generate
              </label>
              <input
                id="numPosts"
                type="number"
                value={numPosts}
                onChange={(e) => setNumPosts(e.target.value)}
                className="input input-bordered w-full text-white"
              />
            </div>
            <button
              onClick={handleGeneratePosts}
              className="btn btn-primary"
              disabled={generating}
            >
              {generating ? 'Generating...' : 'Generate Posts'}
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </div>
        ) : (
          <form onSubmit={handleManualSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="title">
                Title
              </label>
              <input
                id="title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="input input-bordered w-full text-white"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="content">
                Content
              </label>
              <textarea
                id="content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="textarea textarea-bordered w-full text-white"
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Create Post
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </form>
        )}
      </div>
    </div>
  );
};

export default CreatePosts;
