// src/StepTwo.js

import React, { useState } from 'react';

const StepTwo = ({ prevStep, handleSubmit, handleChange, values }) => {
  const [error, setError] = useState('');

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    handleChange(name)({ target: { value: checked } });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    handleChange(name)({ target: { value } });
  };

  const handleFormSubmit = () => {
    if (!values.terms) {
      setError('Please accept the terms and conditions to proceed.');
    } else {
      setError('');
      handleSubmit();
    }
  };

  return (
    <div className="bg-gray-700 p-4 rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Sign Up - Step 2</h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <input
        type="text"
        placeholder="Location"
        onChange={handleInputChange}
        name="location"
        value={values.location}
        className="input input-bordered w-full mb-4"
      />
      <div className="mb-4">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            id="marketingConsent"
            name="marketingConsent"
            checked={values.marketingConsent}
            onChange={handleCheckboxChange}
            className="checkbox checkbox-primary"
          />
          <span className="ml-2">I agree to receive marketing communications.</span>
        </label>
      </div>
      <div className="mb-4">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            checked={values.terms}
            onChange={handleCheckboxChange}
            className="checkbox checkbox-primary"
            required
          />
          <span className="ml-2">I agree to the <a href="https://extremedaytrips.com/privacy-policy" target="_blank" className="text-blue-500">privacy policy</a> and <a href="https://extremedaytrips.com/terms" target="_blank" className="text-blue-500">terms and conditions</a></span>
        </label>
      </div>
      <div className="flex justify-between">
        <button onClick={prevStep} className="btn btn-secondary">Back</button>
        <button onClick={handleFormSubmit} className="btn btn-primary">Sign Up</button>
      </div>
    </div>
  );
};

export default StepTwo;
