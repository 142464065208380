import React from 'react';
import Avatar from 'react-avatar';

const Home = () => {
  return (
    <div className="rounded-xl p-1 shadow-2xl" data-theme="dark">
      <nav className="navbar bg-base-100 px-4 lg:px-16">
        <div className="flex-1">
          <a className="font-urbanist flex items-center justify-center gap-2 text-xl font-bold">
            <span>bloganywhe.re</span>
          </a>
        </div>
        <div className="flex-none">
          <ul className="menu menu-horizontal items-center gap-4 px-1">
            <li><a href="/welcome">Login</a></li>
          </ul>
        </div>
      </nav>
      <main className="relative flex flex-col gap-8">
        <section className="flex flex-col items-center gap-8 p-4 pb-8 pt-16 text-center">
          <h1 className="font-urbanist max-w-4xl text-3xl font-bold tracking-tight lg:text-5xl">
            Add a blog to your website instantly with AI
          </h1>
          <p className="max-w-2xl text-lg font-bold tracking-tight opacity-70">
            Boost your online presence effortlessly with our cutting-edge AI blog generator. Create engaging, SEO-optimized content in seconds.
          </p>
          <div className="flex justify-center gap-x-6">
            <a className="btn btn-lg w-36 min-w-max" href="/signup">Get started</a>
          </div>
          <div className="flex flex-col items-center justify-center gap-3 md:flex-row md:items-start">
            <div className="flex justify-start -space-x-5">
              <Avatar name="Dan" size="50" round={true} src="/socialproof.png" />
              <Avatar name="Alex" size="50" round={true} src="/socialproof.png" />
              <Avatar name="John" size="50" round={true} src="/socialproof.png" />
              <Avatar name="Murray" size="50" round={true} src="/socialproof.png" />
            </div>
            <div className="flex flex-col items-center justify-center gap-1 md:items-start">
              <div className="rating">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-yellow-500">
                  <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clipRule="evenodd"></path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-yellow-500">
                  <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clipRule="evenodd"></path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-yellow-500">
                  <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clipRule="evenodd"></path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-yellow-500">
                  <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clipRule="evenodd"></path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-yellow-500">
                  <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clipRule="evenodd"></path>
                </svg>
                {/* Repeat for additional stars */}
              </div>
              <div className="text-base text-base-content/80">
                <span className="font-semibold text-base-content">20 </span>customers blogging better.
              </div>
            </div>
          </div>
        </section>
        <section className="w-full text-center">
          <div className="bg-primary-color flex h-fit w-full flex-col items-center gap-8 rounded-3xl px-4 py-8">
            <h3 className="font-urbanist text-tertiary-color text-4xl font-bold">
              Overwhelmed by Content Creation?
            </h3>
            <div className="grid grid-cols-1 items-center justify-center gap-8 md:grid-cols-2 md:flex-row md:items-start md:gap-12">
              <div className="h-full w-full max-w-md rounded-lg bg-rose-100/75 p-8 text-rose-700">
                <h4 className="mb-4 text-2xl font-bold">Before bloganywhe.re</h4>
                <ul className="list-inside list-disc space-y-1.5">
                  <li className="flex items-center gap-2 text-left">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4 shrink-0 opacity-75">
                      <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z"></path>
                    </svg>
                    Struggling to find time to write high-quality content
                  </li>
                  <li className="flex items-center gap-2 text-left">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4 shrink-0 opacity-75">
                      <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z"></path>
                    </svg>
                    Difficulty in generating fresh, engaging blog ideas
                  </li>
                  <li className="flex items-center gap-2 text-left">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4 shrink-0 opacity-75">
                      <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z"></path>
                    </svg>
                    Challenges in optimizing blogs for SEO
                  </li>
                  <li className="flex items-center gap-2 text-left">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4 shrink-0 opacity-75">
                      <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z"></path>
                    </svg>
                    Inconsistent posting schedule
                  </li>
                  <li className="flex items-center gap-2 text-left">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4 shrink-0 opacity-75">
                      <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z"></path>
                    </svg>
                    Lack of expertise in effective content writing
                  </li>
                </ul>
              </div>
              <div className="h-full w-full max-w-md rounded-lg bg-emerald-100/70 p-8 text-emerald-700">
                <h4 className="mb-4 text-2xl font-bold">After bloganywhe.re</h4>
                <ul className="list-inside list-disc space-y-1.5">
                  <li className="flex items-center gap-2 text-left">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4 shrink-0 opacity-75">
                      <path fillRule="evenodd" d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd"></path>
                    </svg>
                    AI-driven blog post creation
                  </li>
                  <li className="flex items-center gap-2 text-left">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4 shrink-0 opacity-75">
                      <path fillRule="evenodd" d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd"></path>
                    </svg>
                    Real-time SEO recommendations
                  </li>
                  <li className="flex items-center gap-2 text-left">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4 shrink-0 opacity-75">
                      <path fillRule="evenodd" d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd"></path>
                    </svg>
                    Customizable content templates
                  </li>
                  <li className="flex items-center gap-2 text-left">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4 shrink-0 opacity-75">
                      <path fillRule="evenodd" d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd"></path>
                    </svg>
                    Automated posting schedules
                  </li>
                  <li className="flex items-center gap-2 text-left">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4 shrink-0 opacity-75">
                      <path fillRule="evenodd" d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd"></path>
                    </svg>
                    In-depth analytics and performance tracking
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="pricing" className="flex flex-col items-center gap-8 px-12 py-8 text-center md:px-64">
          <div className="flex flex-col items-center gap-4">
            <h2 className="font-urbanist text-2xl font-bold">
              Choose the Perfect Plan for Your Blogging Needs
            </h2>
            <p className="text-md font-urbanist">
              Flexible pricing plans designed to help you scale your content strategy effortlessly. Pick the one that fits your growth ambitions.
            </p>
          </div>
          <div className="flex flex-col gap-8 lg:flex-row">
            <div className="border-secondary-color bg-primary-color text-tertiary-color relative w-full rounded-3xl border-4 md:w-[400px]">
              <div className="absolute left-1/2 top-0 z-10 -translate-x-1/2 -translate-y-1/2">
                <span className="badge border-0 bg-primary text-xs font-semibold text-primary-content">Recommended</span>
              </div>
              <div className="flex h-full flex-col justify-between gap-4 p-8">
                <div className="flex flex-col items-center justify-between gap-4">
                  <h3 className="text-2xl font-bold">Starter Blogger</h3>
                  <p className="text-2xl font-bold">$0 pm</p>
                </div>
                <ul className="flex w-full flex-grow list-inside list-disc flex-col justify-start gap-4 text-start">
                  <li>Up to 10 blog posts per month</li>
                  <li>Basic SEO optimization</li>
                  <li>Access to standard templates</li>
                  <li>Automated posting</li>
                  <li>Basic analytics</li>
                </ul>
                <a className="btn btn-primary w-full" href="/signup">Get Started</a>
              </div>
            </div>
            <div className="border-secondary-color bg-primary-color text-tertiary-color relative w-full rounded-3xl border-4 md:w-[400px]">
              <div className="flex h-full flex-col justify-between gap-4 p-8">
                <div className="flex flex-col items-center justify-between gap-4">
                  <h3 className="text-2xl font-bold">Pro Blogger</h3>
                  <p className="text-2xl font-bold">$50 pm</p>
                </div>
                <ul className="flex w-full flex-grow list-inside list-disc flex-col justify-start gap-4 text-start">
                  <li>Unlimited blog posts per month</li>
                  <li>Advanced SEO optimization</li>
                  <li>Access to premium templates</li>
                  <li>Automated posting with advanced scheduling</li>
                  <li>Comprehensive analytics and insights</li>
                  <li>Priority customer support</li>
                  <li>Customizable content strategies</li>
                </ul>
                <div className="flex justify-center gap-4">
                  <a className="btn btn-primary w-full" href="/signup">Coming soon</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="mx-auto max-w-xl space-y-4 py-8">
          <div className="flex flex-col items-center gap-4">
            <h2 className="text-center text-3xl font-bold">
              See What Our Users Are Saying
            </h2>
            <p className="text-center text-lg">
              Discover how BlogAnywhere has transformed the content strategies of businesses just like yours.
            </p>
          </div>
          <div className="rating !flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-yellow-500">
              <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clipRule="evenodd"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-yellow-500">
              <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clipRule="evenodd"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-yellow-500">
              <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clipRule="evenodd"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-yellow-500">
              <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clipRule="evenodd"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-yellow-500">
              <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clipRule="evenodd"></path>
            </svg>
          </div>
          <div className="mx-auto max-w-md space-y-2 text-center text-lg font-bold italic leading-relaxed">
            <p>
              <q>BlogAnywhere has revolutionized our content creation process. The AI generates high-quality posts quickly, allowing us to focus on growing our business.</q>
            </p>
          </div>
          <div className="flex items-center justify-center gap-3 md:gap-4">
          <Avatar name="Dan" size="50" round={true} src="/socialproof.png" />
            <div>
              <p className="text-xl font-semibold">User</p>
              <p className="text-base-content-secondary text-sm">100k followers on 𝕏</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
